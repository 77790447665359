<template>
  <transition name="slide">
    <div :class="[analytics_type ==='bio_links' ? 'h-screen' : '']">
        <template v-if="loader" class="py-4">
          <clip-loader :size="size" :color="color"></clip-loader>
        </template>
      <template v-else>
        <div class="flex items-start justify-start bg-[#F7F7FC] py-4" :class="[analytics_type ==='bio_links' ? '!gap-3 !h-full' : '!gap-6' ]">
          <div class="flex flex-col gap-6">
            <div class="bg-white !rounded-[8px] grid_box_shadow">
              <div class=" !w-[24.5rem] !h-[12.5rem] p-4">
                <div class=" flex flex-col gap-y-6  items-start">
                  <p class="font-semibold font-poppins text-[14px] text-[#757A8A]">Clicks</p>
                  <p class="text-[2rem] font-bold text-black font-poppins">
                    {{ commaSeparatedNumber(statistics.hits.total) }}</p>
                  <p class="font-poppins text-[14px] text-[#757A8A]">
                    <template
                      v-if="$route.params.type === 'brand' ||
                                                         $route.params.type === 'call-to-action' ||
                                                          $route.params.type == null">From
                      {{ commaSeparatedNumber(statistics.account_statistics.links) }}
                      Link{{ statistics.account_statistics.links > 1 ? 's' : '' }}
                    </template>
                    <template v-else>Total No. of Clicks</template>
                  </p>
                </div>
              </div>
            </div>

            <div class="bg-white !rounded-[8px] grid_box_shadow">
              <div class="!w-[24.5rem] !h-[12.5rem] p-4">
                <div class="flex flex-col gap-y-6  items-start ">
                  <p class="font-semibold font-poppins text-[14px] text-[#757A8A]">Unique Clicks</p>
                  <p class="text-[2rem] font-bold text-black font-poppins">
                    {{ commaSeparatedNumber(statistics.aggregations.unique_clicks.value) }}</p>
                  <p class=" font-poppins text-[14px] text-[#757A8A]">
                    Total No. of Unique Clicks
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-6">
            <div v-if="analytics_type!='bio_links'" class=" bg-white !rounded-[8px]  grid_box_shadow">
              <div class="!w-[24.5rem] !h-[12.5rem] p-4">
                <div class="flex flex-col gap-y-6  items-start">
                  <p class="font-semibold font-poppins text-[14px] text-[#757A8A]">Conversion</p>
                  <p class="text-[2rem] font-bold text-black font-poppins">{{
                      commaSeparatedNumber(statistics.aggregations.clicked_conversions.doc_count)
                    }}</p>
                  <p class="font-poppins text-[14px] text-[#757A8A]">
                    From {{ statistics.hits.total }} Clicks
                  </p>
                </div>
              </div>
            </div>
            <div v-if="analytics_type!='bio_links'" class=" bg-white !rounded-[8px]  grid_box_shadow">
              <div class="!w-[24.5rem] !h-[12.5rem] p-4">
                <div class="flex flex-col gap-y-6 items-start">
                  <p class="font-semibold font-poppins text-[14px] text-[#757A8A]">Conv. rate</p>
                  <p class="text-[2rem] font-bold text-black font-poppins">{{ getStatisticsConversionRate }}%</p>
                  <p class="font-poppins text-[14px] text-[#757A8A]">
                    Conversion rate
                  </p>
                </div>
              </div>
            </div>


            <div class=" bg-white !rounded-[8px] grid_box_shadow"
                 v-if="$route.params.type == 'link' || $route.params.type == 'call-to-action'">
              <div class="!w-[24.5rem] !h-[12.5rem] p-4">
                <div class="flex flex-col gap-y-6  items-start">
                  <p class="font-semibold font-poppins text-[14px] text-[#757A8A]">Bounce Rate</p>
                  <p class="text-[1.75rem] font-bold text-black font-poppins">{{ getAnalyticsDetail.data.bounces }}%</p>
                  <p class="font-poppins text-[14px] text-[#757A8A]">
                    Bounce Rate
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class=" w-full h-[26.50rem] p-4 bg-white rounded-[8px] grid_box_shadow">
            <div class="">
              <div class=" flex items-center justify-start">
                <div class="">
                  <p v-if="analytics_type!='bio_links'" class="text-[16px] font-bold text-left">Clicks vs Unique Clicks vs Conversion</p>
                  <p v-else class="text-[16px] font-bold text-left">Clicks Performance</p>
                </div>

              </div>
              <div>
                <template v-if="loader">
                  <clip-loader :color="color" :size="size"></clip-loader>
                </template>

                <template v-else-if="activity.clicks">
                  <!--                  <ClicksVsUniqueClicksVsConversionsChart :statistics="statistics" :data="clicks_chart"></ClicksVsUniqueClicksVsConversionsChart>-->
                  <highcharts :options="clicks_chart" ref="clicks_chart"></highcharts>
                </template>
                <template v-else>
                  <no-events-available></no-events-available>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="analytics_type!='bio_links'"  class="w-full h-full mb-4 bg-white rounded-[8px] grid_box_shadow  ">
        <div class=" flex items-center justify-between border-b w-full p-4">
          <div><p class="text-[16px] font-bold text-left">Visitors</p></div>
          <div class="flex justify-center items-center gap-x-3">
            <div @click="browserState=true, osState=false, countryState=false, referralState=false"
                 :class="[ browserState ? '!bg-[#E3F2FF] !border-[#2973E5] ' : '' ]"
                 class=" cursor-pointer border-[1.5px] border-[#D2D5DF] text-[14px] font-normal rounded-[4px] text-[#3C4549] px-2 py-2 hover:bg-[#F2F3F8] leading-none">
              <p :class="[browserState ? '!text-[#2961D2]' : '']">Browser</p>
            </div>
            <div @click="browserState=false, osState=true, countryState=false, referralState=false"
                 :class="[ osState ? '!bg-[#E3F2FF] !border-[#2973E5] ' : '' ]"
                 class="cursor-pointer border-[1.5px] border-[#D2D5DF] text-[14px] font-normal rounded-[4px] text-[#3C4549] px-2 py-2 hover:bg-[#F2F3F8] leading-none">
              <p :class="[osState ? '!text-[#2961D2]' : '']">Operating System</p>
            </div>
            <div @click="browserState=false, osState=false, countryState=true, referralState=false"
                 :class="[ countryState ? '!bg-[#E3F2FF] !border-[#2973E5] ' : '' ]"
                 class="cursor-pointer border-[1.5px] border-[#D2D5DF] text-[14px] font-normal rounded-[4px] text-[#3C4549] px-2 py-2 hover:bg-[#F2F3F8] leading-none">
              <p :class="[countryState ? '!text-[#2961D2]' : '']">Country</p>
            </div>
            <div @click="browserState=false, osState=false, countryState=false, referralState=true"
                 :class="[ referralState ? '!bg-[#E3F2FF] !border-[#2973E5] ' : '' ]"
                 class="cursor-pointer border-[1.5px] border-[#D2D5DF] text-[14px] font-normal rounded-[4px] text-[#3C4549] px-2 py-2 hover:bg-[#F2F3F8] leading-none">
              <p :class="[referralState ? '!text-[#2961D2]' : '']">Source/Referral</p>
            </div>
          </div>
        </div>

        <div class="flex justify-center h-full items-start">
          <div class=" px-4 w-[43%] border-r py-24">
            <template v-if="loader">
              <clip-loader :color="color" :size="size"></clip-loader>
            </template>
            <template v-else-if="browserState && activity.clicks" class="flex justify-center items-center ">
              <BrowsersPieChart
                :transformedBrowserList="transformedBrowserList"
              ></BrowsersPieChart>
            </template>
            <template v-else-if="osState && activity.clicks" class="flex justify-center items-center ">
              <OsPieChart
                :transformedOsList="transformedOsList"
              ></OsPieChart>
            </template>
            <template v-else-if="countryState && activity.clicks" class="flex justify-center items-center ">
              <CountryPieChart
                :transformedCountriesList="transformedCountriesList"
              ></CountryPieChart>
            </template>
            <template v-else-if="referralState && activity.clicks" class="flex justify-center items-center ">
              <VisitedFromPieChart
                :transformedVisitedFromList="transformedVisitedFromList"
              ></VisitedFromPieChart>
            </template>
            <template v-else>
              <no-events-available></no-events-available>
            </template>
          </div>
          <div class="w-full h-full">
            <div v-if="browserState">
              <BrowsersTableListV2 :statistics="statistics"></BrowsersTableListV2>
            </div>
            <div v-if="osState">
              <OsTableListV2 :statistics="statistics"></OsTableListV2>
            </div>
            <div v-if="countryState">
              <CountriesTableListV2 :statistics="statistics"></CountriesTableListV2>
            </div>
            <div v-if="referralState">
              <VisitedFromTableListV2 :statistics="statistics"></VisitedFromTableListV2>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import CountriesGraph from './CountriesViewForAnalytics.vue'
import OSTableList from './tables/OSTableList'
import BrowsersTableList from './tables/BrowsersTableList'
import CountriesTableList from './tables/CountriesTableList'
import VisitedFromTableList from './tables/VisitedFromTableList'
import {
  DARK_PURPLE_LOADER_COLOR,
  overviewOfClicksPerformance,
  overviewOfConversionsPerformance,
  pieVisitedCountriesChart,
  pieVisitedFromChart
} from '../../../../common/constants'
import orderBy from "lodash/orderBy";

let clicksPerformanceChart = overviewOfClicksPerformance()
clicksPerformanceChart.series[1] = {
  name: 'Unique Clicks',
  data: [],
  color: '#2560D7',
}
clicksPerformanceChart.series[2] = {
  type: 'spline',
  name: 'Conversions',
  data: [],
  lineWidth: 2,
  color: '#4FEADC',
  marker: {
    enabled: false,
  }
}
export default ({
  props: {
    statistics: {},
    loader: {},
    analytics_type: null
  },
  components: {
    BrowsersTableListV2: () => import('./tables/BrowsersTableListV2.vue'),
    BrowsersPieChart: () => import('./charts/BrowsersPieChart.vue'),
    OsPieChart: () => import('./charts/OsPieChart.vue'),
    OsTableListV2: () => import('./tables/OsTableListV2.vue'),
    CountriesTableListV2: () => import('./tables/CountriesTableListV2.vue'),
    CountryPieChart: () => import('./charts/CountryPieChart.vue'),
    VisitedFromTableListV2: () => import('./tables/VisitedFromTableListV2.vue'),
    VisitedFromPieChart: () => import('./charts/VisitedFromPieChart.vue'),
  },
  data: function () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      clicks_chart: clicksPerformanceChart,
      conversions_chart: overviewOfConversionsPerformance(),
      visited_from_countries_chart: pieVisitedCountriesChart(),
      visited_from_domains_chart: pieVisitedFromChart(),
      os_sorting: 'clicks_desc',
      country_sorting: 'clicks_desc',
      referral_sorting: 'clicks_desc',
      activity: {
        clicks: true,
        conversions: true,
        countries: true,
        referral: true
      },
      browserState: true,
      osState: false,
      countryState: false,
      referralState: false,
      browser_sorting: 'clicks_desc',
    }
  },
  mounted() {
    const performanceHistory = this.statistics.aggregations.performance_history.buckets
    this.performanceChart(performanceHistory, this.activity, this.conversions_chart, this.clicks_chart, true)
  },
  created() {
    this.fetchLinks()
  },
  computed: {
    ...mapGetters([]),
    getStatisticsConversionRate() {
      if (this.analytics_type != 'bio_links') {
        return this.computeConversionRate(this.statistics.aggregations)
      }
    },
    getBrowserList() {
      if (this.statistics.aggregations.visited_from_browsers.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.browser_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_browsers.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    },
    transformedBrowserList() {
      return this.getBrowserList.map(item => ({
        name: item.key,
        value: item.doc_count
      }))
    },
    getOSList() {
      if (this.statistics.aggregations.visited_from_os.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.os_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_os.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    },
    transformedOsList() {
      return this.getOSList.map(item => ({
        name: item.key,
        value: item.doc_count
      }))
    },
    getCountriesList() {
      if (this.statistics.aggregations.countries.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.country_sorting)
        let results = orderBy(this.statistics.aggregations.countries.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    },
    transformedCountriesList() {
      return this.getCountriesList.map(item => ({
        name: item.key,
        value: item.doc_count
      }))
    },
    getVisitedFromList() {
      if (this.statistics.aggregations.visited_from_domains.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.referral_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_domains.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    },
    transformedVisitedFromList() {
      return this.getVisitedFromList.map(item => ({
        name: item.key,
        value: item.doc_count
      }))
    }
  },
  methods: {
    ...mapActions(['fetchLinks'])
  }
})
</script>
