<template>
    <div class="table_container">
        <table class="table list-content">
            <thead class="">
            <tr>
                <th style="text-align: center;width: 200px !important;">Operating System
                </th>
                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="os_sorting = (os_sorting === 'clicks_asc') ? 'clicks_desc': 'clicks_asc'">
                    Clicks

                    <i v-if="os_sorting==='clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="os_sorting==='clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>

                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="os_sorting = (os_sorting === 'unique_clicks_asc') ? 'unique_clicks_desc': 'unique_clicks_asc'">
                    Unique Clicks

                    <i v-if="os_sorting==='unique_clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="os_sorting==='unique_clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>
                <!--
                                                                        <th class="cursor_pointer sorted_row" style="text-align: center;"
                                                                            @click.prevent="changeAnalyticSort('bounce',getAnalyticsDetail.overviewOsSort,'os')">
                                                                            Bounce Rate
                                                                            <i v-if="getAnalyticsDetail.overviewOsSort=='bounce_asc'"
                                                                               class="first table_filter_caret fas fa-caret-up"></i>
                                                                            <i v-if="getAnalyticsDetail.overviewOsSort=='bounce_desc'"
                                                                               class="table_filter_caret fas fa-caret-down"></i>
                                                                        </th>-->
                <th v-if="!forBio" class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="os_sorting = (os_sorting === 'conversions_asc') ? 'conversions_desc': 'conversions_asc'">
                    Conversions

                    <i v-if="os_sorting==='conversions_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="os_sorting==='conversions_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>
                <th v-if="!forBio" class="cursor_pointer sorted_row" style="text-align: center;"
                >
                    Conversion Rate
                </th>
            </tr>
            </thead>

            <tbody class="list-content-body">

            <tr v-if="statistics.aggregations.visited_from_os.buckets.length == 0">
                <td colspan="9">
                    <div class="col">
                        <p class="no-rows text-center">
                            <no-events-available></no-events-available>
                        </p>
                    </div>
                </td>
            </tr>

            <template v-else>
                <tr v-for="analytic in getOSList">
                    <td class="text-capitalize">
                      <img :src="fetchOSIcon(analytic.key)" class="mr-1" style="width: 30px;">
                        {{computeKeyAnalytics(analytic.key)}}
                    </td>
                    <td style="text-align: center;">{{analytic.doc_count}}</td>
                    <td style="text-align: center;">{{analytic.unique_clicks.value}}</td>
                    <!--<td style="text-align: center;">
                        {{analytic.bounce}}%
                    </td>-->
                    <td v-if="!forBio" style="text-align: center;">
                        {{analytic.clicked_conversions.doc_count}}
                    </td>
                    <td v-if="!forBio" style="text-align: center;">
                        {{Math.round((analytic.clicked_conversions.doc_count / analytic.doc_count) * 100)}}%
                    </td>
                </tr>
            </template>

            </tbody>

        </table>
    </div>
</template>
<script>
import orderBy from 'lodash/orderBy'

export default (
  {
    props: {
      statistics: {},
      forBio: false
    },
    data () {
      return {
        os_sorting: 'clicks_desc'
      }
    },
    computed: {
      getOSList () {
        if (this.statistics.aggregations.visited_from_os.buckets.length > 0) {
          const sortingByArray = this.sortingValue(this.os_sorting)
          let results = orderBy(this.statistics.aggregations.visited_from_os.buckets, [sortingByArray[0]], [sortingByArray[1]])
          return results
        }
        return []
      }
    }
  }
)
</script>
